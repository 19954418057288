<template>
    <div id="research">
        <div class="content">
            <div class="information">
                <!--<div class="bg">
                    <img :src="require('@/assets/images/research/banner_bg.png')" alt="">
                </div>-->
                <div class="list">
                    <div class="breadcrumbs">
                        <el-breadcrumb>
                            <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.href">
                                {{ item.text }}
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <ul>
                        <li v-for="(item,index) in list" :key="index">
                            <h4>{{ $i18n.locale === 'en' ? item.enTitle : item.cnTitle }}</h4>
                            <img :src="staticPath + ($i18n.locale === 'en' ? item.enLogo : item.cnLogo)" />
                            <p>{{ $i18n.locale === 'en' ? item.enContentSummary : item.cnContentSummary }}</p>
                            <i class="icon" @click="toDetail(item)"></i>
                        </li>
                    </ul>
                    <div class="pagination">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            @current-change="getList"
                            :current-page.sync="pageCurrent"
                            :page-size="pageSize"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    export default {
        name: "Research",
        components: {},
        data() {
            return {
                staticPath,
                breadcrumbs: [
                    {
                        text: "首页",
                        pathName: 'Home',
                        disabled: false,
                        href: '/'
                    },{
                        text: "研学旅",
                        pathName: 'Research',
                        disabled: false,
                        href: '/research'
                    }, {
                        text: "研学动态",
                        pathName: 'ResearchInformation',
                        disabled: true,
                        href: ''
                    }
                ],
                options: [
                    {
                        label: '身份证',
                        value: 1
                    }, {
                        label: '护照',
                        value: 2
                    }, {
                        label: '港澳通行证',
                        value: 3
                    }, {
                        label: '其他',
                        value: 0
                    }
                ],
                type: 1,
                licenseNum: '',
                list: [],
                showDialog: false,
                dialogVisible: false,
                contId: '',
                parentId: '',
                pageCurrent: 1,
                pageSize: 8,
                total: 0,

            };
        },
        methods: {
            checkLicenseNum() {
                this.showDialog = true;
                this.dialogVisible = true;
            },
            closeDialog() {
                this.dialogVisible = false;
                setTimeout(() => {
                    this.showDialog = false;
                }, 400)
            },
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const articleId = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'NewsDetail',
                        params: {
                            id: articleId
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
            getList() {
                const params = {
                    navigationId: this.contId,
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize
                }
                this.$http.get(urls.getSubpageList, {params}).then(res => {
                    if (res.data.status === 200) {
                        this.list = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
        },
        mounted() {
            this.contId = window.atob(decodeURIComponent(this.$route.query.n))
            this.parentId = window.atob(decodeURIComponent(this.$route.query.p))
            console.log(this.contId, this.parentId)
            this.getList();
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #research {
        @{deep} .navbar {
            position: relative;
        }
        .content {
            .information {
                background: url("../../assets/images/research/research_bg.png") no-repeat center center;
                background-size: 100% 100%;
                .bg {
                    padding-right: 10vw;
                }
                .list {
                    width: 80%;
                    margin: 0 auto 3%;
                    @{deep} .breadcrumbs {
                        margin: 0 0 2vw;
                        
                        .el-breadcrumb__inner a, 
                        .el-breadcrumb__separator,
                        .el-breadcrumb__item,
                        .el-breadcrumb__inner.is-link {
                            color: #606266;
                            font-weight: normal;
                            font-size: 24px;
                        }
                    }
                    ul {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        li {
                            width: 22%;
                            background: #fff;
                            line-height: 24px;
                            margin: 0 4% 4% 0;
                            box-shadow: 0px 10px 40px rgba(0, 181, 255, 0.08);
                            &:nth-child(4n) {
                                margin-right: 0;
                            }
                            img {
                                width: 100%;
                                height: 10vw;
                            }
                            h4 {
                                color: #6b6667;
                                padding: 5px 15px;
                                display: -webkit-box;
                                overflow: hidden;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                            >p {
                                height: 44px;
                                margin: 5px 15px 0;
                                font-size: 12px;
                                line-height: 22px;
                                color: #aaaaaa;
                                display: -webkit-box;
                                overflow: hidden;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                            >span {
                                display: inline-block;
                                width: 100%;
                                margin: 10px auto;
                                text-align: center;
                            }
                            .icon {
                                display: inline-block;
                                width: 1.45vw;
                                height: 1.07vw;
                                background: url("../../assets/images/icon_arrow.png") no-repeat 100%
                                100%;
                                background-size: contain;
                                cursor: pointer;
                                margin: 10px 20px 20px;
                            }
                        }
                    }
                    .pagination {
                        text-align: center;
                        margin-top: 2%;
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #research{
            .content{
                .btn-more{
                    .fontFn(140,width);
                    .fontFn(44,height);
                    .fontFn(44,line-height);
                }
                .information{
                    background:none;
                    .bg{
                        padding-right: 0;
                        background:url('../../assets/images/phone/module_bg7.png') no-repeat right bottom;
                        background-size: auto 100%;
                        padding-bottom: 4%;
                    }
                    .list{
                        width: 94.8%;
                        @{deep} .breadcrumbs {
                            margin-bottom: 4%;
                            .el-breadcrumb__inner a, 
                            .el-breadcrumb__separator,
                            .el-breadcrumb__item,
                            .el-breadcrumb__inner.is-link {
                                color: #606266;
                                .fontFn(12);
                            }
                        }
                        ul{
                            display: block;
                            font-size: 0;
                            white-space: nowrap;
                            overflow-x: auto;
                            
                            li{
                                display: inline-block;
                                vertical-align: top;
                                width: 85.3%;
                                margin-right: 10px;
                                .fontFn(375,min-height);
                                img{
                                    height: auto;
                                }
                                &>p{
                                    white-space: normal;
                                }
                                h4{
                                    color:#6B6667;
                                    .fontFn(16);
                                    white-space: normal;
                                }
                                .icon{
                                    .fontFn(27,width);
                                    .fontFn(18,height);
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }   
    }
</style>